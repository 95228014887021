import React, { Fragment, useState, useEffect } from "react";
import { Link, Route } from "react-router-dom";

import Logo from "../../showfuer_logo.png";

import "./Navigation.scss";

function Navigation(props) {
  return (
    <Fragment>
      <nav id={"showfeur-nav"}>
        <Link to={"/showfeur"}>
          <img src={Logo} alt={"Showfeur logo"} />
        </Link>
        <p>123,244</p>
        <button>Brett</button>
      </nav>
    </Fragment>
  );
}

export default Navigation;
