import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import styles from "./Introduction.module.scss";

import Profile from "../../../Media/profile.png";
import Resume from "../../../Media/resume.pdf";

function Introduction() {
  return (
    <Fragment>
      <section id={styles.introduction}>
        <div>
          <div className={styles.titles}>
            <h2>Welcome, I'm</h2>
            <h1>Brett Severin</h1>
            <subtitle>Fullstack Developer</subtitle>
          </div>
          <div className={styles.buttons}>
            <a href={Resume} download={"Brett Severin Resume.pdf"}>
              Download Resume
            </a>
            <Link to={"/#contact"}>Contact</Link>
          </div>
        </div>
        <div className={styles.profile}>
          <img src={Profile} />
        </div>
      </section>
    </Fragment>
  );
}

export default Introduction;
