import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Link, Route, Redirect, Switch } from "react-router-dom";
import Alphabet from "../Games/Alphabet/Alphabet";
import Match from "../Games/Match/Match";
import Minesweeper from "../Games/Minesweeper/Minesweeper";
import Snake from "../Games/Snake/Snake";
import Wordle from "../Games/Wordle/Wordle";
import Arcade from "../page";

import "./Base.scss";

function ArcadeBase() {
  document.title = "Brett Severin | Arcade";

  return (
    <Fragment>
      {/* <MCATContextProvider> */}
      <main id={"arcade"}>
        <Switch>
          <Route exact path={"/arcade/minesweeper"} component={Minesweeper} />
          <Route exact path={"/arcade/snake"} component={Snake} />
          <Route exact path={"/arcade/alphabet"} component={Alphabet} />
          <Route exact path={"/arcade/wordle"} component={Wordle} />
          <Route exact path={"/arcade/match"} component={Match} />
          <Route path={"/arcade"} component={Arcade} />
        </Switch>
      </main>
      {/* </MCATContextProvider> */}
    </Fragment>
  );
}

export default ArcadeBase;
