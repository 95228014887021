import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import Introduction from "./Sections/Introduction/Introduction";
import styles from "./page.module.scss";
import About from "./Sections/About/About";
import Contact from "./Sections/Contact/Contact";
import Skills from "./Sections/Skills/Skills";
import Footer from "./Sections/Footer/Footer";

import transitions from "../Elements/Transitions/Transitions";
import Projects from "./Sections/Projects/Projects";

function PortfolioPage() {
  function getLayer() {
    return (
      <layer
        style={{
          "--time": `${Math.round(Math.random() * 120) + 90}s`,
          "--x-move": `${Math.round(Math.random() * 190) - 95}%`,
          "--y-move": `${Math.round(Math.random() * 190) - 95}%`,
          "--direction": `${Math.round(Math.random() * 10 - 5) > 0 ? 1 : -1}`,
        }}
      >
        {Array(50)
          .fill(null)
          .map((_) => (
            <floater
              style={{
                "--size": `${Math.round(Math.random() * 10)}px`,
                "--x-pos": `${Math.round(Math.random() * 90) + 5}%`,
                "--y-pos": `${Math.round(Math.random() * 90) + 5}%`,
              }}
            />
          ))}
      </layer>
    );
  }

  return (
    <Fragment>
      <main className={styles.portfolio + " " + transitions.blur}>
        <Introduction />
        <About />
        <Skills />
        <Projects />
        <Contact />
        <ul className={styles.socials}>
          <li>
            <Link to={"/r/linkedin"} target={"_blank"}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z" />
              </svg>
            </Link>
          </li>
          <li>
            <Link to={"/r/instagram"} target={"_blank"}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z" />
              </svg>
            </Link>
          </li>
        </ul>
        {/* <Footer /> */}
        {getLayer()}
        {getLayer()}
        {getLayer()}
        {getLayer()}
      </main>
    </Fragment>
  );
}

export default PortfolioPage;
