import React, { Fragment, useState, useEffect } from "react";
import GamePage from "../../GamePage/GamePage";

import "./Alphabet.scss";

function Alphabet() {
  const [current, setCurrent] = useState(65);
  const [entry, setEntry] = useState("");
  const [time, setTime] = useState(0);
  const [timeHistory, setTimeHistory] = useState([]);
  const [gameActive, setGameActive] = useState(false);
  const [showBreakdown, setShowBreakdown] = useState(false);

  function checkLetter(text) {
    let letter = text[text.length - 1];

    if (
      letter.charCodeAt(0) === current ||
      letter.charCodeAt(0) === current + 32
    ) {
      setCurrent(current + 1);
      setEntry(entry + letter);
      var newHistory = timeHistory;
      newHistory.push(time.toFixed(2));
      setTimeHistory(newHistory);
    }
  }

  function reset() {
    clearTimeout(timer);
    setGameActive(false);
    setCurrent(65);
    setTime(0);
    setEntry("");
    setShowBreakdown(false);
  }

  function isWinner() {
    return !gameActive && current === 91;
  }

  function getBreakDown() {
    if (showBreakdown && isWinner()) {
      return entry.split("").map((letter, i) => (
        <li>
          <strong>{letter}:</strong> {timeHistory[i]}
        </li>
      ));
    }
  }

  var timer;
  useEffect(() => {
    if (gameActive) {
      timer = setTimeout(() => {
        setTime(time + 0.01);
      }, 10);
    }
  }, [gameActive, time]);

  useEffect(() => {
    if (!gameActive && current === 66) {
      setGameActive(true);
      setTime(0);
    } else if (gameActive && current === 91) {
      setGameActive(false);
    }
  }, [current]);

  return (
    <Fragment>
      <GamePage title={"Alphabet"}>
        <div id={"alphabet-game"}>
          <p>
            {!isWinner()
              ? String.fromCharCode(current).toUpperCase()
              : "Success!"}
          </p>
          <input
            value={entry}
            onChange={(e) => checkLetter(e.target.value)}
            spellcheck={false}
          />
          <button className={"reset slide"} onClick={() => reset()}>
            Reset
          </button>
          <button
            className={"score slide"}
            onClick={() => setShowBreakdown(!showBreakdown)}
          >
            {time.toFixed(2)} seconds
          </button>
          <ul className={"history"} data-show={showBreakdown}>
            {getBreakDown()}
          </ul>
        </div>
      </GamePage>
    </Fragment>
  );
}

export default Alphabet;
