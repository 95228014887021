import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import Input from "../../../Elements/Input/Input";

import { useAdminContext } from "../../Contexts/context";

import styles from "./page.module.scss";

function LoginPage(props) {
  const adminContext = useAdminContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [verified, setVerified] = useState(null);

  function handleFormSubmit(e) {
    e.preventDefault();
    if (verified === null) {
      setVerified(adminContext.verifyUser(username, password));
    } else {
      setVerified(null);
    }
  }

  useEffect(() => {
    if (verified !== null) setVerified(null);
  }, [username, password]);

  return (
    <Fragment>
      {adminContext.isLoggedIn() && <Redirect to={"/admin"} />}
      <div id={styles.LoginPage}>
        <h1>Admin Control</h1>
        <form onSubmit={handleFormSubmit}>
          <Input
            label={"Username"}
            value={username}
            setValue={setUsername}
            required
          />
          <Input
            label={"Password"}
            value={password}
            setValue={setPassword}
            type={"password"}
            required
          />
          <input type={"submit"} value={"Login"} />

          {verified !== null && !verified && (
            <p>Incorrect username or password.</p>
          )}
        </form>
      </div>
    </Fragment>
  );
}

export default LoginPage;
