import React, { Fragment, useState, useEffect } from "react";
import { Link, Route } from "react-router-dom";

import "./Model.scss";

function Model(props) {
  const [open, setOpen] = [props.open, props.setOpen];
  let data = {
    title: "Spider-Man: No Way Home",
    image:
      "https://m.media-amazon.com/images/M/MV5BZWMyYzFjYTYtNTRjYi00OGExLWE2YzgtOGRmYjAxZTU3NzBiXkEyXkFqcGdeQXVyMzQ0MzA0NTM@._V1_.jpg",
    id: "1",
  };

  return (
    <Fragment>
      <div className={"show-model" + (open ? "open" : "")}>
        <header>
          <img src={data.image} alt={data.title + " poster"} />
          <h1>{data.title}</h1>
          <p>2021 • 2 seasons</p>
        </header>
        <ul>
          {Array(4)
            .fill(null)
            .map((item, index) => (
              <li>
                <h2>Season {index + 1}</h2>
                <ul>
                  {Array(20)
                    .fill(null)
                    .map((item, index) => (
                      <li>
                        <button className={"slide center"}>{index + 1}</button>
                      </li>
                    ))}
                </ul>
              </li>
            ))}
        </ul>
        <button className={"slide center material-icons"} onClick={() => setOpen(false)}>close</button>
      </div>
    </Fragment>
  );
}

export default Model;
