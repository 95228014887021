import React, { Fragment, useState, useEffect } from "react";
import {
  BrowserRouter,
  Link,
  Route,
  Redirect,
  Switch,
  useLocation,
} from "react-router-dom";
import MCATContextProvider, { useMCATContext } from "../Contexts/context";
import Navigation from "../Elements/Navigation/Navigation";
import AuthPage from "../Pages/Auth/page";
import LogPage from "../Pages/Log/page";
import MainPage from "../Pages/Main/page";
import TopicsPage from "../Pages/Topics/page";

import "./Base.scss";

function MCATBase() {
  document.title = "Brett Severin | MCAT";

  return (
    <Fragment>
      <MCATContextProvider>
        <main id={"MCAT"}>
          <Switch>
            <Route exact path={"/mcat/login"} component={AuthPage} />
            <Route path={"/mcat"}>
              <Navigation />
              <Switch>
                <Route exact path={"/mcat"} component={MainPage} />
                <Route
                  path={"/mcat/log/:format?/:year?/:month?/:day?"}
                  component={LogPage}
                />
                <Route exact path={"/mcat/topics"} component={TopicsPage} />
              </Switch>
            </Route>
          </Switch>
        </main>
      </MCATContextProvider>
    </Fragment>
  );
}

export default MCATBase;
