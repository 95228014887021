import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useMCATContext } from "../../Contexts/context";

import styles from "./page.module.scss";
import transitions from "../../../../Elements/Transitions/Transitions.module.scss";

function MainPage() {
  const dataContext = useMCATContext();

  return (
    <Fragment>
      <div id={styles.MainPage} className={transitions.blur}>
        <h1>Welcome back to study, {dataContext.getUserName()}!</h1>
        <ul>
          <li>
            <Link to={"/mcat/log"} className={"material-icons slide center"}>
              calendar_month
            </Link>
          </li>
          <li>
            <Link to={"/mcat/topics"} className={"material-icons slide center"}>
              topic
            </Link>
          </li>
          <li>
            <Link
              to={"/mcat/questions"}
              className={"material-icons slide center"}
            >
              quiz
            </Link>
          </li>
          <li>
            <Link
              to={"/mcat/account"}
              className={"material-icons slide center"}
            >
              person
            </Link>
          </li>
        </ul>
      </div>
    </Fragment>
  );
}

export default MainPage;
