import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Link, Route } from "react-router-dom";
import { useMCATContext } from "../../../../../Contexts/context";

import styles from "./Preview.module.scss";

function Preview(props) {
  const mcatContext = useMCATContext();
  const date = props.date ? props.date : new Date();
  const day = props.date ? props.date.getDate() : null;

  const tasks = mcatContext.getTasksByDate(date.toISOString().split("T")[0]);

  function isToday() {
    if (new Date().toDateString() === date.toDateString()) {
      return styles.today;
    }
  }

  return (
    <Fragment>
      <li className={[styles["calendar-preview"], isToday()].join(" ")}>
        <Link
          to={
            "/mcat/log/day/" +
            [date.getFullYear(), date.getMonth() + 1, date.getDate()].join("/")
          }
        >
          {day}
        </Link>
        <ul>
          {tasks
            .sort((task1, task2) => task1.rank > task2.rank)
            .sort((task1, task2) => task1.isDone > task2.isDone)
            .slice(0, 4)
            .map((task) => (
              <li
                className={task.isDone ? styles.done : ""}
                style={{ background: task.color }}
              >
                {task.title}
              </li>
            ))}
          {tasks.length > 4 ? (
            <Link
              className={styles.more}
              to={
                "/mcat/log/day/" +
                [date.getFullYear(), date.getMonth() + 1, date.getDate()].join(
                  "/"
                )
              }
            >
              Show {tasks.length - 4} more
            </Link>
          ) : (
            ""
          )}
        </ul>
      </li>
    </Fragment>
  );
}

export default Preview;
