import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Progress from "../Progress/Progress";
import { useMCATContext } from "../../Contexts/context";

import "./Timer.scss";

function Timer(props) {
  const mcatContext = useMCATContext();
  const date = new Date().toISOString().split("T")[0];
  const minutes = mcatContext.getProgressMinutes(date);
  const goal = mcatContext.getProgressGoal(date);

  const [timerOn, setTimerOn] = useState(false);

  useEffect(() => {
    if (timerOn) {
      mcatContext.startTimer(date);
    } else {
      mcatContext.stopTimer();
    }
  }, [timerOn]);

  useEffect(() => {
    mcatContext.updateProgress(date);
  }, []);

  return (
    <Fragment>
      <div className={"timer"}>
        <Progress
          value={minutes / goal}
          showPercentage={true}
          active={timerOn}
        />
        <button
          onClick={() => setTimerOn(!timerOn)}
          className={"material-icons slide center"}
        >
          {timerOn ? "pause" : "play_arrow"}
        </button>
      </div>
    </Fragment>
  );
}

export default Timer;
