import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import styles from "./Topic.module.scss";

function Topic(props) {
  const data = props.data ? props.data : "";

  return (
    <Fragment>
      <li className={styles.topic}>
        <button className={"slide"} style={data.style}>
          {data.title}
          <button className={styles["skill" + data.skill]}></button>
        </button>
      </li>
    </Fragment>
  );
}

export default Topic;
