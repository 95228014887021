import React, { Fragment, useState, useEffect } from "react";
import { useMCATContext } from "../../../../../Contexts/context";
import Progress from "../../../../../Elements/Progress/Progress";

import styles from "./Day.module.scss";
import Task from "./Elements/Task/Task";

function Day(props) {
  const mcatContext = useMCATContext();
  const date = props.date ? props.date : new Date();

  const tasks = mcatContext
    .getTasksByDate(date.toISOString().split("T")[0])
    .sort((task1, task2) => task1.rank > task2.rank)
    .sort((task1, task2) => task1.isDone > task2.isDone);

  const [edit, setEdit] = useState(false);

  return (
    <Fragment>
      <div className={styles["calendar-day"]}>
        <h1>
          {date.getFullMonth()} {date.getDate()}, {date.getFullYear()}
        </h1>
        <button
          className={"material-icons slide center"}
          onClick={() => setEdit(!edit)}
        >
          {edit ? "edit_off" : "edit"}
        </button>
        <div>
          <div className={styles.todolist}>
            <h2>Tasks</h2>
            <ul data-edit={edit}>
              {tasks.map((task) => (
                <Task data={task} edit={edit} />
              ))}
            </ul>
          </div>
          <div className={styles.log}>
            <h2>Progress</h2>
            <span>
              <Progress value={0.25} goal={1} showPercentage={true} />
              <p>25/180 minutes</p>
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Day;
