import React, { Fragment, useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Input from "../../../Elements/Input/Input";

import Logo from "../showfuer_logo.png";

import "./Login.scss";

function Login() {
  document.title = "Brett Severin | Showfeur | Login";

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [redirect, setRedirect] = useState("");

  function handleVerification(key) {
    if (key === "Enter") {
      setRedirect(<Redirect to={"/showfeur"} />);
    }
  }

  return (
    <Fragment>
      {redirect}
      <div
        id={"showfeur-login-page"}
        onKeyPress={(e) => handleVerification(e.key)}
      >
        <img src={Logo} alt={"Showfeur Logo"} />
        <Input
          label={"Username"}
          value={username}
          setValue={setUsername}
          type={"Username"}
        />
        <Input
          label={"Password"}
          value={password}
          setValue={setPassword}
          type={"password"}
        />
        <button onClick={() => handleVerification("Enter")} className={"slide"}>
          Log In
        </button>
        <Link to={"/shwofeur/signup"}>Don't have an account? Sign up!</Link>
      </div>
    </Fragment>
  );
}

export default Login;
