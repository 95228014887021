import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./page.module.scss";

function MainPage() {
  const [total, setTotal] = useState("0");
  const [operator, setOperator] = useState("+");
  const [next, setNext] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);

  function operate() {}

  function clearTotal() {
    showAnswer ? setNext(0) : setTotal(0);
  }

  function switchSign() {
    setOperator("*");
    setNext(-1);
    setShowAnswer(true);
  }

  function evaluate() {
    setTotal(total + " " + operator + " " + next);
    setNext(0);
    setShowAnswer(true);
  }

  function makePercentage() {
    setOperator("*");
    setNext(0.01);
    setShowAnswer(true);
  }

  function typeNumber(number) {
    setNext(next * 10 + number);
  }

  useEffect(() => {
    if (showAnswer) {
      setTotal(total + " " + operator + " " + next);
    }
  }, showAnswer);

  useEffect(() => {
    setShowAnswer(false);
  }, next);

  useEffect(() => {
    if (next !== 0) {
      evaluate();
    }
  }, operator);

  return (
    <Fragment>
      <div id={styles["calculator-page"]}>
        <p>{total}</p>
        <p>{operator}</p>
        <div className={styles.calculator}>
          <h1>{showAnswer ? eval(total) : next}</h1>
          <div className={styles.buttons}>
            <button onClick={clearTotal}>{showAnswer ? "C" : "AC"}</button>
            <button onClick={switchSign}>+/-</button>
            <button onClick={makePercentage}>%</button>
            <button onClick={() => setOperator("/")}>÷</button>
            <button onClick={() => typeNumber(7)}>7</button>
            <button onClick={() => typeNumber(8)}>8</button>
            <button onClick={() => typeNumber(9)}>9</button>
            <button onClick={() => setOperator("*")}>X</button>
            <button onClick={() => typeNumber(4)}>4</button>
            <button onClick={() => typeNumber(5)}>5</button>
            <button onClick={() => typeNumber(6)}>6</button>
            <button onClick={() => setOperator("-")}>-</button>
            <button onClick={() => typeNumber(1)}>1</button>
            <button onClick={() => typeNumber(2)}>2</button>
            <button onClick={() => typeNumber(3)}>3</button>
            <button onClick={() => setOperator("+")}>+</button>
            <button onClick={() => typeNumber(0)}>0</button>
            <button>.</button>
            <button onClick={() => evaluate()}>=</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default MainPage;
