import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation, Redirect, useParams } from "react-router-dom";
import Calendar from "./Elements/Calendar/Calendar";
import Schedule from "./Elements/Schedule/Schedule";

import styles from "./page.module.scss";
import transitions from "../../../../Elements/Transitions/Transitions";

function LogPage() {
  const location = useParams();

  const today = new Date();
  const format =
    location.format && ["month", "day"].includes(location.format)
      ? location.format
      : "month";

  const year = location.year ? location.year : today.getFullYear();
  const month = location.month ? location.month : today.getMonth() + 1;
  const day = location.day ? location.day : today.getDate();

  const [date, setDate] = useState(new Date(year, month - 1, day));

  useEffect(() => {
    setDate(new Date(year, month - 1, day));
  }, [location.format, location.year, location.month, location.day]);

  return (
    <Fragment>
      <div id={styles.LogPage} className={transitions.blur}>
        <div>
          <Calendar format={format} date={date} />
        </div>
        <Schedule format={format} date={date} />
      </div>
    </Fragment>
  );
}

export default LogPage;
