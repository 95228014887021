import React, { Fragment, useState, useEffect } from "react";
import { Link, Route } from "react-router-dom";

import "./Movie.scss";

function Movie(props) {
  let data = {
    title: "Spider-Man: No Way Home",
    image:
      "https://m.media-amazon.com/images/M/MV5BZWMyYzFjYTYtNTRjYi00OGExLWE2YzgtOGRmYjAxZTU3NzBiXkEyXkFqcGdeQXVyMzQ0MzA0NTM@._V1_.jpg",
    id: "1",
  };

  return (
    <Fragment>
      <Link className={"movie"} to={"/showfeur"}>
        <button
          className={"material-icons"}
        >
          add
        </button>
        <img src={data.image} alt={data.title + " poster"} />
        {data.title}
      </Link>
    </Fragment>
  );
}

export default Movie;
