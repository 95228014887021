import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Link, Route } from "react-router-dom";
import ShowfeurContextProvider, { ShowfeurContext } from "../Context";
import Login from "../Login/Login";
import Showfeur from "../page";

function ShowfeurBase() {
  document.title = "Brett Severin | Showfeur";

  return (
    <Fragment>
      <ShowfeurContextProvider>
        <Route exact path={"/showfeur"} component={Showfeur} />
        <Route exact path={"/showfeur/login"} component={Login} />
      </ShowfeurContextProvider>
    </Fragment>
  );
}

export default ShowfeurBase;
