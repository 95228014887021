import React, { Fragment, useState, useEffect } from "react";
import {
  BrowserRouter,
  Link,
  Route,
  Redirect,
  Switch,
  useLocation,
} from "react-router-dom";
import MainPage from "../Pages/Main/page";

function CalculatorBase() {
  document.title = "Brett Severin | Calculator";

  return (
    <Fragment>
      <main id={"Calculator"}>
        <Switch>
          <Route exact path={"/calculator"} component={MainPage} />
        </Switch>
      </main>
    </Fragment>
  );
}

export default CalculatorBase;
