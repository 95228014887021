import React, { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";

import Portfolio from "./Portfolio/page";
import ArcadeBase from "./Arcade/Base/Base";
import ShowfeurBase from "./Projects/Showfeur/Base/Base";
import MCATBase from "./Projects/MCAT/Base/Base";
import CalculatorBase from "./Projects/Calculator/Base/Base";

import "./App.css";
import RedirectorBase from "./Projects/Redirector/Base/Base";
import PortfolioBase from "./Portfolio/Base/Base";
import AdminBase from "./Admin/Base/Base";

import "./Utilities/Extensions.jsx";

function App() {
  useEffect(() => {
    let defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    if (defaultDark) {
      console.log("Currently in dark mode");
    } else {
      console.log("Currently not in dark mode");
    }
  }, window.matchMedia("(prefers-color-scheme: dark)").matches);

  return (
    <Fragment>
      <BrowserRouter>
        <Scroller />
        <Switch>
          <Route exact path={"/"} component={PortfolioBase} />
          <Route path={"/admin"} component={AdminBase} />
          <Route path={"/arcade"} component={ArcadeBase} />
          <Route path={"/showfeur"} component={ShowfeurBase} />
          <Route path={"/mcat"} component={MCATBase} />
          <Route path={"/calculator"} component={CalculatorBase} />
          <Route path={"/r"} component={RedirectorBase} />
          <Route path={"/"} component={PortfolioBase} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
}

function Scroller() {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let el = document.querySelector(location.hash);
      if (el) el.smoothScrollIntoView();
    }
  }, [location.key]);

  return <></>;
}

export default App;
