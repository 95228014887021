import React, { Fragment, useState, useEffect } from "react";
import Input from "../../../Elements/Input/Input";
import GamePage from "../../GamePage/GamePage";

import Icons from "./Icons.json";

import "./Match.scss";

function Match(props) {
  const [matches, setMatches] = useState(props.width ? props.width : 8);

  const [gameState, setGameState] = useState(false);
  const [score, setScore] = useState(0);
  const [flipped, setFlipped] = useState([]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [board, setBoard] = useState(Array(matches * 2).fill(""));

  function buildBoard() {
    var i = -0.5;
    var newBoard = [...board].map(() => {
      i += 0.5;
      return Icons[Math.floor(i)];
    });

    newBoard = newBoard
      .sort(() => Math.random() < Math.random())
      .sort(() => Math.random() < Math.random());

    setBoard(newBoard);
  }

  function isFlipped(index) {
    return flipped.includes(index);
  }

  function flipSpace(...indexes) {
    if (!gameState) setGameState(true);
    var newFlipped = [...flipped];

    indexes.forEach((index) => {
      if (!isFlipped(index)) {
        newFlipped.push(index);
        setScore(score + 1);
      } else {
        newFlipped = newFlipped.filter((space) => space !== index);
      }
    });

    setFlipped(newFlipped);
  }

  function getSymbol(index) {
    return isFlipped(index) ? board[index] : "";
  }

  function handleClick(index) {
    if (!isFlipped(index)) {
      flipSpace(index);
    }
  }

  function resetGame() {
    buildBoard();
    setFlipped([]);
    setScore(0);
    clearTimeout(timer);
    setGameState(false);
    setElapsedTime(0);
  }

  function isWinner() {
    return flipped.length === matches;
  }

  useEffect(() => {
    if (flipped.length % 2 === 0) {
      var flips = flipped.slice(flipped.length - 2);

      if (board[flips[0]] !== board[flips[1]]) {
        setTimeout(() => {
          flipSpace(flips[0], flips[1]);
        }, 1000);
      }
    }
  }, [flipped]);

  useEffect(() => {
    buildBoard();
  }, []);

  var timer;
  useEffect(() => {
    if (gameState) {
      timer = setTimeout(() => {
        setElapsedTime(elapsedTime + 1);
      }, 1000);
    }
  }, [gameState, elapsedTime]);

  useEffect(() => {
    if (isWinner()) {
      console.log("I am a winner");
      setGameState(false);
      clearTimeout(timer);
    }
  }, [flipped]);

  useEffect(() => {
    resetGame();
    setBoard(Array(matches * 2).fill(""));
  }, [matches]);

  return (
    <Fragment>
      <GamePage title={"Match"}>
        <div className={"inputs"}>
          <Input
            label={"Matches"}
            value={matches}
            setValue={setMatches}
            type={"number"}
            disabled={gameState}
            min={2}
            max={50}
            step={2}
          />
        </div>
        <div id={"match-game"}>
          <header>
            <p>{score}</p>
            <button className={"material-icons"} onClick={() => resetGame()}>
              restart_alt
            </button>
            <p>
              {Math.floor(elapsedTime / 60)}:
              {("0" + (elapsedTime % 60)).slice(-2)}
            </p>
          </header>
          <div
            className={"board"}
            style={{ "--width": Math.ceil(Math.sqrt(matches * 2)) }}
            data-winner={isWinner()}
          >
            {board.map((space, index) => (
              <button
                className={isFlipped(index) ? "flipped" : "slide center"}
                onClick={() => handleClick(index)}
              >
                {getSymbol(index)}
              </button>
            ))}
          </div>
        </div>
      </GamePage>
    </Fragment>
  );
}

export default Match;
