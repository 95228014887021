import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import "./GamePage.scss";

function GamePage(props) {
  document.title =
    "Brett Severin | Arcade" + (props.title ? " | " + props.title : "");

  return (
    <Fragment>
      <div id={"game-page"}>
        <Link to={"/arcade"} className={"slide center material-icons"}>
          sports_esports
        </Link>
        <div className={"game"}>{props.children}</div>
      </div>
    </Fragment>
  );
}

export default GamePage;
