import React, { Fragment, useState, useEffect } from "react";

import styles from "./Skills.module.scss";

function Skills() {
  const skills = {
    frontend: [
      {
        title: "HTML",
        level: "90",
      },
      {
        title: "SASS",
        level: "75",
      },
      {
        title: "JavaScript",
        level: "85",
      },
      {
        title: "React.js",
        level: "85",
      },
      {
        title: "Grails",
        level: "60",
      },
      {
        title: "Swift",
        level: "50",
      },
    ],
    backend: [
      {
        title: "Python",
        level: "70",
      },
      {
        title: "SQL",
        level: "65",
      },
      {
        title: "Go",
        level: "50",
      },
      {
        title: "Java",
        level: "35",
      },
      {
        title: "C++",
        level: "35",
      },
      {
        title: "R",
        level: "25",
      },
    ],
  };
  return (
    <Fragment>
      <section id={styles.skills}>
        <header>
          <h2>What Skills I Have</h2>
          <h1>My Experience</h1>
        </header>
        <div>
          <div>
            <h1>Frontend Development</h1>
            <ul>
              {skills["frontend"].map((skill) => (
                <li>
                  <i>verified</i>
                  <h2>{skill.title}</h2>
                  <div style={{ "--level": skill.level }}></div>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h1>Backend Development</h1>
            <ul>
              {skills["backend"].map((skill) => (
                <li>
                  <i>verified</i>
                  <h2>{skill.title}</h2>
                  <div
                    style={{ "--level": skill.level }}
                    data-level={skill.level}
                  ></div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default Skills;
