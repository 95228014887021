import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Link, Route } from "react-router-dom";

import "./Progress.scss";

function Progress(props) {
  const value = props.value ? props.value : 0;
  const max = props.max ? props.max : 1;
  const showPercentage = props.showPercentage;
  const active = props.active;

  return (
    <Fragment>
      <span
        className={"progress"}
        data-showPercentage={showPercentage}
        data-active={active}
        data-time={Math.round((value / max) * 100) + "%"}
        style={{ "--degree": value / max }}
      />
    </Fragment>
  );
}

export default Progress;
