Element.prototype.smoothScrollIntoView = function() {
  window.scrollBy({
    top: this.getBoundingClientRect().top,
    behavior: "smooth",
  });
};

Date.prototype.getFullMonth = function() {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]

    return months[this.getMonth()]
}