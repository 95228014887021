import React, { Fragment, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import AdminContextProvider from "../Contexts/context";
import LoginPage from "../Pages/Authentication/page";

function AdminBase(props) {
  document.title = "Brett Severin | Admin";

  return (
    <Fragment>
      <AdminContextProvider>
        <Switch>
          <Route exact path={"/admin/login"} component={LoginPage} />
          {/* <Route path={"/admin"} component={AdminPage} /> */}
        </Switch>
      </AdminContextProvider>
    </Fragment>
  );
}

export default AdminBase;
