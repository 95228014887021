import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Progress from "../Progress/Progress";
import { useMCATContext } from "../../Contexts/context";
import Timer from "../Timer/Timer";

import styles from "./Navigation.module.scss";

function Navigation(props) {
  const mcatContext = useMCATContext();
  const date = new Date().toISOString().split("T")[0];
  const minutes = mcatContext.getProgressMinutes(date);
  const goal = mcatContext.getProgressGoal(date);

  const [openPages, setOpenPages] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);

  const [timerOn, setTimerOn] = useState(false);

  const buttons = [
    {
      link: "/mcat",
      title: "Home",
      icon: "home",
    },
    {
      link: "/mcat/log",
      title: "Log",
      icon: "calendar_month",
    },
    {
      link: "/mcat/topics",
      title: "Topics",
      icon: "topic",
    },
    {
      link: "/mcat",
      title: "Questions",
      icon: "quiz",
    },
    {
      link: "/mcat/account",
      title: "Account",
      icon: "person",
    },
  ];

  useEffect(() => {
    if (timerOn) {
      mcatContext.startTimer(date);
    } else {
      mcatContext.stopTimer();
    }
  }, [timerOn]);

  useEffect(() => {
    mcatContext.updateProgress(date);
  }, []);

  return (
    <Fragment>
      <nav className={styles["mcat-navigation"]} data-open={openPages}>
        <ul>
          {buttons.map((button) => (
            <li>
              <Link
                to={button.link}
                title={button.title}
                className={"slide center"}
                onClick={() => setOpenPages(false)}
                target={button.target}
              >
                {button.icon}
              </Link>
            </li>
          ))}
          <li>
            <button
              className={styles.menu + " slide center"}
              onClick={() => setOpenPages(!openPages)}
            >
              <div></div>
            </button>
          </li>
        </ul>
      </nav>
    </Fragment>
  );
}

export default Navigation;
