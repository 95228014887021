import React, { Fragment, useState, useEffect } from "react";
import GamePage from "../../GamePage/GamePage";

import "./Minesweeper.scss";

function Minesweeper(props) {
  let width = props.width ? props.width : 10;
  let height = props.height ? props.height : 10;
  let minesCount = props.mines ? props.mines : 10;

  const [gameActive, setGameActive] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [flagCount, setFlagCount] = useState(0);

  const [board, setBoard] = useState(
    Array(height * width)
      .fill(null)
      .map(() => ({
        nearby: 0,
        isOpen: false,
        isFlagged: false,
      }))
  );

  // Checks if a space is a mine
  function isMine(index) {
    return board[index].isMine;
  }

  // Checks if a space is open
  function isOpen(index) {
    return board[index].isOpen;
  }

  // Checks if a space is a flagged
  function isFlagged(index) {
    return board[index].isFlagged;
  }

  // Gets the number of mines nearby a space
  function getNearby(index) {
    return board[index].nearby;
  }

  // Gets all a space's neighbors
  function getNeighbors(index) {
    var neighbors = [];

    let topIndex = index - width;
    if (index - width >= 0) neighbors.push(topIndex);

    let bottomIndex = index + width;
    if (bottomIndex < width * height) neighbors.push(bottomIndex);

    if (!(index % width === 0)) {
      neighbors.push(index - 1);
      if (neighbors.includes(topIndex)) neighbors.push(topIndex - 1);
      if (neighbors.includes(bottomIndex)) neighbors.push(bottomIndex - 1);
    }

    if (!(index % width === width - 1)) {
      neighbors.push(index + 1);
      if (neighbors.includes(topIndex)) neighbors.push(topIndex + 1);
      if (neighbors.includes(bottomIndex)) neighbors.push(bottomIndex + 1);
    }
    return neighbors;
  }

  // Creates a new game board, setting all mine locations and nearby counts
  function makeBoard(index) {
    var newBoard = [...board];

    newBoard.forEach((space) => {
      space.nearby = 0;
      space.isOpen = false;
      space.isFlagged = false;
      space.isMine = false;
    });

    var newBombIndexes = [];
    while (newBombIndexes.length < minesCount) {
      var newMine = Math.round(Math.random() * (board.length - 1));
      if (newMine !== index && !newBombIndexes.includes(newMine)) {
        newBombIndexes.push(newMine);
        newBoard[newMine].isMine = true;

        var neighbors = getNeighbors(newMine);
        neighbors.forEach((neighbor) => (newBoard[neighbor].nearby += 1));
      }
    }

    setFlagCount(0);
    setBoard(newBoard);
  }

  // Starts the game with a new board
  function startGame(index) {
    makeBoard(index);
    setGameActive(true);
  }

  // Resets all game components
  function resetGame() {
    resetBoard();
    setGameActive(false);
    clearTimeout(timer);
    clearTimeout(neighborDelay);
    setElapsedTime(0);
    setFlagCount(0);
  }

  // Resets the board, removing all mines and flags, closing all spaces, and resetting nearby counts
  function resetBoard() {
    var newBoard = [...board];

    newBoard.forEach((space) => {
      space.nearby = 0;
      space.isOpen = false;
      space.isFlagged = false;
      space.isMine = false;
    });

    setBoard(newBoard);
  }

  // Handles all user interactions via left click, right click, or spacebar
  function handleClick(e, index) {
    if (!gameActive) {
      startGame(index);
    }

    if (e.button === 2) {
      // Right click
      flagSpace(index);
    } else if (e.button === 0) {
      // Left click
      openSpace(index);
    } else if (e.keyCode === 32) {
      // Spacebar
      if (isOpen(index)) {
        var neighbors = getNeighbors(index).filter((neighbor) =>
          isFlagged(neighbor)
        );
        if (neighbors.length === getNearby(index)) openNeighbors(index);
      } else {
        flagSpace(index);
      }
    }
  }

  // Opens a space when clicked
  function openSpace(index) {
    if (isFlagged(index)) return; // Cannot open a flagged space

    // End the game if the space clicked is a mine
    if (isMine(index)) {
      setGameActive(false);
      return;
    }

    let newBoard = [...board];
    newBoard[index].isOpen = true;
    setBoard(newBoard);

    // If a space is not adjacent to any mines, open all neighbors
    if (getNearby(index) === 0) openNeighbors(index);
  }

  // Gets a space's neighbors, then opens each
  // Used when a user left clicks space has 0 neighbors, or when a user presses spacebar on an open space
  var neighborDelay;
  function openNeighbors(index) {
    neighborDelay = setTimeout(() => {
      var neighbors = getNeighbors(index).filter(
        (neighbor) => !isOpen(neighbor) && !isFlagged(neighbor)
      );
      console.log(gameActive);
      neighbors.forEach((neighbor) => openSpace(neighbor));
    }, 50);
  }

  // Flags a space
  // Used when a user right clicks a space or presses spacebar on an open space where the number of adjacent flags is equal to the space's nearby count
  function flagSpace(index) {
    if (isOpen(index)) return;
    var newBoard = [...board];
    newBoard[index].isFlagged = !isFlagged(index);
    setFlagCount(flagCount + (newBoard[index].isFlagged ? 1 : -1));
    setBoard(newBoard);
  }

  // Determines if the nearby count should be shown
  function getSymbol(index) {
    return isOpen(index) && !isMine(index) && getNearby(index) > 0
      ? getNearby(index)
      : "";
  }

  // Creates the class list for each space
  function getStyles(index) {
    return (
      (isOpen(index) ? " open" : "") +
      (isFlagged(index) ? " flagged" : "") +
      (isMine(index) && !gameActive && elapsedTime > 0 ? " mine" : "")
    );
  }

  // Checks if the user has won the game
  // Occurs when the number of unopened spaces is equivalent to the number of mines
  function isWinner() {
    return board.filter((space) => !space.isOpen).length === minesCount;
  }

  // Creates the event listener to allow spacebar use
  // Resets every time the board updates
  useEffect(() => {
    var x = 0;
    var y = 0;
    window.onmousemove = (e) => {
      x = e.pageX;
      y = e.pageY;
    };

    window.onkeyup = (e) => {
      if (e.code === "Space") {
        let el = document.elementFromPoint(x, y);
        if (el.tagName.toLowerCase() == "button") {
          var num = el.getAttribute("data-id");
          handleClick(e, parseInt(num));
        }
      }
    };
  }, [board]);

  // Starts and increments the timer
  var timer;
  useEffect(() => {
    timer = setTimeout(() => {
      if (gameActive) {
        setElapsedTime(elapsedTime + 1);
      }
    }, 1000);
  }, [gameActive, elapsedTime]);

  // Checks if the user has won the game
  useEffect(() => {
    if (isWinner()) {
      setGameActive(false);
    }
  }, [board]);

  return (
    <Fragment>
      <GamePage title={"Minesweeper"}>
        <div className={"minesweeper"} style={{ "--board-width": width }}>
          <header>
            <p>{minesCount - flagCount}</p>
            <button className={"material-icons"} onClick={() => resetGame()}>
              restart_alt
            </button>
            <p>
              {Math.floor(elapsedTime / 60)}:
              {("0" + (elapsedTime % 60)).slice(-2)}
            </p>
          </header>
          <div
            className={"board"}
            data-active={gameActive}
            data-winner={isWinner()}
          >
            {board.map((space, index) => (
              <button
                data-id={index}
                className={"slide center" + getStyles(index)}
                onContextMenu={(e) => e.preventDefault()}
                onMouseUp={(e) => handleClick(e, index)}
              >
                {getSymbol(index)}
              </button>
            ))}
          </div>
        </div>
      </GamePage>
    </Fragment>
  );
}

export default Minesweeper;
