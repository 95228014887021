import React, { Fragment, useState, useEffect } from "react";
import GamePage from "../../GamePage/GamePage";

import "./Wordle.scss";

import words from "./Words";

function Wordle() {
  const [word, setWord] = useState();
  const [guess, setGuess] = useState("");
  const [guessHistory, setGuessHistory] = useState("");
  const [error, setError] = useState(false);

  function handleKey(key) {
    if (isLoser() || isWinner()) return;

    var newGuess = guess.split("");
    if (key === "Backspace") {
      setError("");
      newGuess.pop();
      setGuess(newGuess.join(""));
    } else if ("abcdefghijklmnopqrstuvwxyz".includes(key)) {
      if (guess.length < 5) {
        newGuess.push(key);
        setGuess(newGuess.join(""));
      }
    } else if (key === "Enter" && guess.length === 5) {
      if (words.includes(guess)) {
        setGuessHistory(guessHistory + guess);
        setGuess("");
      } else {
        setError("Not a valid word");
      }
    }
  }

  function isWinner() {
    return guessHistory.includes(word);
  }

  function isLoser() {
    return guessHistory.length === 30;
  }

  const keyboard = [
    "q",
    "w",
    "e",
    "r",
    "t",
    "y",
    "u",
    "i",
    "o",
    "p",
    "a",
    "s",
    "d",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    "Enter",
    "z",
    "x",
    "c",
    "v",
    "b",
    "n",
    "m",
    "Backspace",
  ];

  function getClassName(letter, index) {
    var classes = [];
    if (word.charAt(index) === letter) {
      classes.push("correct");
    } else if (word.includes(letter)) {
      classes.push("includes");
    } else {
      classes.push("incorrect");
    }

    return classes.join(" ");
  }

  useEffect(() => {
    let index = Math.ceil(Math.random() * words.length) - 1;
    setWord(words[index]);
  }, []);

  return (
    <Fragment>
      <GamePage title={"Wordle"}>
        <div className={"wordle"}>
          <div className={"board "}>
            {guessHistory.split("").map((letter, index) => (
              <p className={getClassName(letter, index % 5)}>{letter}</p>
            ))}
            {guess.split("").map((letter) => (
              <p>{letter}</p>
            ))}
            {Array(30 - guessHistory.length - guess.length)
              .fill(null)
              .map(() => (
                <p></p>
              ))}

            {error ? <label>{error}</label> : ""}
          </div>
          <input
            type={"text"}
            value={guess}
            onKeyDown={(e) => handleKey(e.key)}
            autoFocus
            onBlur={(e) =>
              setTimeout(() => {
                e.target.focus();
              }, 1)
            }
            disabled={isLoser()}
          />
          <div className={"keyboard"}>
            {keyboard.map((letter) => (
              <button
                className={[
                  "slide",
                  "center",
                  guessHistory.includes(letter) ? "guessed" : "",
                ].join(" ")}
                onClick={() => handleKey(letter)}
              >
                {letter}
              </button>
            ))}
          </div>
        </div>
      </GamePage>
    </Fragment>
  );
}

export default Wordle;
