import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getGames } from "./Games/Games";

import "./page.scss";

function Arcade() {
  return (
    <Fragment>
      <div id={"arcade-page"}>
        <Link to={"/"} className={"slide center material-icons"}>
          home
        </Link>
        <h1>Play now</h1>
        <ul>
          {getGames().map((game) => (
            <li>
              <Link to={"/arcade/" + game.id} className={"slide"}>
                {game.title} <i className={"material-icons"}>arrow_forward</i>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
}

export default Arcade;
