import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Link, Route } from "react-router-dom";
import { useMCATContext } from "../../../../Contexts/context";
import Day from "./Day/Day";
import Preview from "./Preview/Preview";

import styles from "./Schedule.module.scss";

function Schedule(props) {
  const mcatContext = useMCATContext();

  const today = new Date();
  const format = props.format ? props.format : "month";
  const [date, setDate] = useState(props.date ? props.date : new Date());

  useEffect(() => {
    setDate(props.date);
  }, [props.date]);

  function getMonthLength() {
    var thisDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return thisDate.getDate();
  }

  function getWeekday(day) {
    const newDate = new Date(date.getFullYear(), date.getMonth(), day);
    return newDate.getDay() + 1;
  }

  const days = Array(getMonthLength())
    .fill(null)
    .map((_, i) => (
      <Preview date={new Date(date.getFullYear(), date.getMonth(), i + 1)} />
    ));

  function getNeighborDate(direction) {
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth() + (format === "month" ? direction : 0),
      date.getDate() + (format === "day" ? direction : 0)
    );

    return [newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate()];
  }

  return (
    <Fragment>
      <div className={styles.schedule}>
        <header>
          <Link
            to={
              "/mcat/log/" +
              [
                format,
                today.getFullYear(),
                today.getMonth() + 1,
                today.getDate(),
              ].join("/")
            }
          >
            Today
          </Link>
          <span>
            <Link
              to={"/mcat/log/" + [format, ...getNeighborDate(-1)].join("/")}
              className={"material-icons"}
            >
              keyboard_arrow_left
            </Link>
            <Link
              to={"/mcat/log/" + [format, ...getNeighborDate(1)].join("/")}
              className={"material-icons"}
            >
              keyboard_arrow_right
            </Link>
          </span>
          <h1>
            {date.getFullMonth()} {date.getFullYear()}
          </h1>
          <Link
            to={
              "/mcat/log/" +
              [
                format === "month" ? "day" : "month",
                date.getFullYear(),
                date.getMonth() + 1,
                date.getDate(),
              ].join("/")
            }
          >
            {format === "month" ? "Day" : "Month"}
          </Link>
        </header>
        {format === "month" ? (
          <ul style={{ "--start": getWeekday(1) }}>{days}</ul>
        ) : (
          ""
        )}
        {format === "day" ? <Day date={date} /> : ""}
      </div>
    </Fragment>
  );
}

export default Schedule;
