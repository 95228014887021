import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from "../../../../Elements/Dropdown/Dropdown";
import { useMCATContext } from "../../Contexts/context";
import Topic from "./Elements/Topic/Topic";

import styles from "./page.module.scss";

function TopicsPage() {
  const dataContext = useMCATContext();

  const [sort, setSort] = useState("subject");

  const topicsAll = dataContext.getTopics();

  const [topics, setTopics] = useState(
    topicsAll.sort((topic1, topic2) => topic1[sort] > topic2[sort])
  );

  const subjects = [...new Set(topicsAll.map((topic) => topic.subject))]
    .map((subject) => subject)
    .sort((subject1, subject2) => subject1 > subject2);

  const [filters, setFilters] = useState([]);

  function toggleSubject(subject) {
    var newFilters = [...filters];
    if (!newFilters.includes(subject)) {
      newFilters.push(subject);
    } else {
      newFilters = newFilters.filter((filter) => filter !== subject);
    }

    console.log(newFilters);
    setFilters(newFilters);
  }

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    var newTopics = [...topicsAll].filter(
      (topic) => !filters.includes(topic.subject)
    );

    setTopics(newTopics);
  }, [filters]);

  useEffect(() => {
    setTopics(
      topicsAll
        .sort((topic1, topic2) => topic1[sort] > topic2[sort])
        .filter((topic) => !filters.includes(topic.subject))
    );
  }, [sort]);

  return (
    <Fragment>
      <div id={styles.TopicsPage}>
        <div className={styles.sidebar}>
          <h1>Subjects</h1>
          <div>
            <Dropdown selected={sort}>
              <button onClick={() => setSort("subject")}>Subject</button>
              <button onClick={() => setSort("title")}>A &rarr; Z</button>
              <button onClick={() => setSort("skill")}>Skill</button>
            </Dropdown>
            <ul>
              {subjects.map((subject) => (
                <li>
                  <button
                    onClick={() => toggleSubject(subject)}
                    className={
                      (filters.includes(subject) ? styles.inactive : "") +
                      " slide"
                    }
                  >
                    {subject}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.body}>
          <h1>Topics</h1>
          <ul>
            {topics.map((topic) => (
              <Topic data={topic} />
            ))}
          </ul>
        </div>
        <div className={styles.questions}>
          <h1>Questions</h1>
          <ul>
            {questions.map((question) => (
              <Topic data={question} />
            ))}
          </ul>
        </div>
      </div>
    </Fragment>
  );
}

export default TopicsPage;
