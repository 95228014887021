import React, { Fragment, useState, useEffect } from "react";
import GamePage from "../../GamePage/GamePage";

import "./Snake.scss";

function Snake(props) {
  const [gameActive, setGameActive] = useState(false);

  let BOARD_WIDTH = props.width ? props.width : 40;
  let BOARD_HEIGHT = props.height ? props.height : 40;

  const [score, setScore] = useState(0);
  const [snake, setSnake] = useState([
    { x: BOARD_WIDTH / 2, y: BOARD_HEIGHT / 2 },
  ]);

  let SNAKE_SPEED = 1;
  const [SNAKE_VELOCITY, setVelocity] = useState({ x: 0, y: 0 });

  function getSnakeHead() {
    return snake[snake.length - 1];
  }

  function changeVelocity(x1, y1) {
    if (SNAKE_VELOCITY.x === 0 && x1 !== 0) {
      setVelocity({ x: x1, y: 0 });
    } else if (SNAKE_VELOCITY.y === 0 && y1 !== 0) {
      setVelocity({ x: 0, y: y1 });
    }
  }

  function update() {
    var newSnake = snake;

    var head = getSnakeHead();
    head.x = head.x + SNAKE_VELOCITY.x;
    head.y = head.y + SNAKE_VELOCITY.y;
    newSnake.push(head);

    var feed = false;

    if (
      // snake.some((piece) => piece.x === head.x && piece.y === head.y) ||
      head.x < 1 ||
      head.x > BOARD_WIDTH ||
      head.y < 1 ||
      head.y > BOARD_HEIGHT
    ) {
      console.log(snake, head);
      setGameActive(false);
    } else {
      if (head.x === food.x && head.y === food.y) {
        setScore(score + 1);
        feed = true;
      }
    }
    setSnake(newSnake);
  }

  const [food, setFood] = useState({ x: 0, y: 0 });
  function makeFood() {
    let newFood = {
      x: Math.floor(Math.random() * BOARD_WIDTH) + 1,
      y: Math.floor(Math.random() * BOARD_HEIGHT) + 1,
    };

    if (snake.some((piece) => piece.x === newFood.x && piece.y === newFood.y)) {
      makeFood();
    } else {
      setFood(newFood);
    }
  }

  function draw() {
    return (
      <Fragment>
        {snake.map((piece) => (
          <div
            className={"snake"}
            style={{ gridRowStart: piece.y, gridColumnStart: piece.x }}
          />
        ))}
        <div
          className={"food"}
          style={{ gridRowStart: food.y, gridColumnStart: food.x }}
        />
      </Fragment>
    );
  }

  document.onkeydown = arrowKey;

  function arrowKey(key) {
    if (!gameActive) {
      setGameActive(true);
    }

    switch (key.key) {
      case "ArrowLeft":
        changeVelocity(-1, 0);
        break;
      case "ArrowRight":
        changeVelocity(1, 0);
        break;
      case "ArrowUp":
        changeVelocity(0, -1);
        break;
      case "ArrowDown":
        changeVelocity(0, 1);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    makeFood();
  }, [score]);

  useEffect(() => {
    if (gameActive) {
      setScore(0);
      setSnake([{ x: BOARD_WIDTH / 2, y: BOARD_HEIGHT / 2 }]);
    }
  }, [gameActive]);

  useEffect(() => {
    if (gameActive) {
      setTimeout(() => {
        console.log("move");
        update();
      }, 1000 * (1 / SNAKE_SPEED));
    }
  }, [snake]);

  return (
    <Fragment>
      <GamePage title={"Snake"}>
        <div className={"snake-game"}>
          <header>
            <button onClick={() => setGameActive(!gameActive)}>
              {gameActive ? "Stop" : "Start"}
            </button>
            <label>{score}</label>
            <label>
              [{SNAKE_VELOCITY.x} | {SNAKE_VELOCITY.y}]
            </label>
          </header>
          <div
            className={"game-board" + (gameActive ? " active" : "")}
            style={{
              "--board-width": BOARD_WIDTH,
              "--board-height": BOARD_HEIGHT,
            }}
          >
            {draw()}
          </div>
        </div>
      </GamePage>
    </Fragment>
  );
}

export default Snake;
