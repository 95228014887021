import React, { createContext, useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import TestTasks from "../TestData/Tasks";
import TestTopics from "../TestData/Topics";

export const MCATContext = createContext();

function MCATContextProvider(props) {
  const [tasks, setTasks] = useState(TestTasks);

  function addTask(date) {
    var newTasks = [...tasks];
    const id = Math.max(...tasks.map((task) => task.id)) + 1;
    newTasks.push({
      id: id,
      title: "New Task " + id,
      level: 1,
      isDone: false,
      date: date,
    });
    setTasks(newTasks);
  }

  function editTask(id, newTask) {
    var newTasks = [...tasks];
    const index = newTasks.findIndex((task) => task.id === id);
    newTasks[index] = newTask;
    setTasks(newTasks);
  }

  function getTasksByDate(date) {
    return tasks.filter((task) => task.date === date);
  }

  function deleteTask(id) {
    var newTasks = [...tasks].filter((task) => task.id !== id);
    setTasks(newTasks);
  }

  const [progresses, setProgresses] = useState({
    "2022-03-1": {
      minutes: 200,
      goal: 180,
    },
    "2022-03-02": {
      minutes: 160,
      goal: 180,
    },
    "2022-03-03": {
      minutes: 180,
      goal: 180,
    },
    "2022-03-04": {
      minutes: 90,
      goal: 180,
    },
    "2022-03-05": {
      minutes: 25,
      goal: 180,
    },
    "2022-03-06": {
      minutes: 0,
      goal: 180,
    },
  });

  function addProgress(date) {
    var newProgresses = { ...progresses };
    newProgresses[date] = {
      minutes: 0,
      goal: 180,
    };
    setProgresses(newProgresses);
  }

  function updateProgress(date, minutes) {
    var newProgresses = { ...progresses };
    if (!newProgresses[date]) {
      addProgress(date);
      return;
    }
    newProgresses[date]["minutes"] = newProgresses[date]["minutes"] + minutes;
    setProgresses(newProgresses);
  }

  function deleteProgress(date, minutes = null) {
    var newProgresses = { ...progresses };
    if (minutes) {
      newProgresses[date]["minutes"] = newProgresses[date]["minutes"] - minutes;
    } else {
      delete newProgresses[date];
    }
    setProgresses(newProgresses);
  }

  function updateGoal(date, goal) {
    var newProgresses = { ...progresses };
    newProgresses[date]["goal"] = newProgresses[date]["goal"] + goal;
    setProgresses(newProgresses);
  }

  function getProgress(date) {
    return progresses[date]
      ? progresses[date]["minutes"] / progresses[date]["goal"]
      : 0;
  }

  function getProgressMinutes(date) {
    return progresses[date] ? progresses[date]["minutes"] : 0;
  }

  function getProgressGoal(date) {
    return progresses[date] ? progresses[date]["goal"] : 0;
  }

  const [timerOn, setTimerOn] = useState(false);
  const [timerDate, setTimerDate] = useState("");

  var timer;
  function startTimer(date) {
    setTimerOn(true);
    setTimerDate(date);
  }

  function stopTimer() {
    setTimerOn(false);
    clearTimeout(timer);
  }

  useEffect(() => {
    timer = setTimeout(() => {
      if (timerOn) {
        updateProgress(timerDate, 1);
      }
    }, 1000);
  }, [timerOn, progresses]);

  const [users, setUsers] = useState([
    {
      id: 1,
      first_name: "Shelby",
      last_name: "Perelli",
      username: "shelby",
      password: "",
    },
  ]);

  const [user, setUser] = useState(null);

  function verifyUser(username, password) {
    const thisUser = users.find((user) => user.username === username);

    if (thisUser && thisUser.password === password) {
      setUser(thisUser);
      return true;
    }
    return false;
  }

  function isLoggedIn() {
    return user !== null || false;
  }

  function getUserName(lastName = false) {
    if (!user) return "";
    var name = user.first_name;

    if (lastName) {
      name += " " + user.last_name;
    }

    return name;
  }

  const [topics, setTopics] = useState(TestTopics);

  function getTopics() {
    return topics;
  }

  return (
    <MCATContext.Provider
      value={{
        addTask: addTask,
        editTask: editTask,
        deleteTask: deleteTask,
        getTasksByDate: getTasksByDate,
        addProgress: addProgress,
        updateProgress: updateProgress,
        deleteProgress: deleteProgress,
        updateGoal: updateGoal,
        getProgress: getProgress,
        getProgressMinutes: getProgressMinutes,
        getProgressGoal: getProgressGoal,
        startTimer: startTimer,
        stopTimer: stopTimer,
        verifyUser: verifyUser,
        isLoggedIn: isLoggedIn,
        getUserName: getUserName,
        getTopics: getTopics,
      }}
    >
      {isLoggedIn() ? "" : <Redirect to={"/mcat/login"} />}
      {props.children}
    </MCATContext.Provider>
  );
}

export default MCATContextProvider;

export const useMCATContext = () => useContext(MCATContext);
