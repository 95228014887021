import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import CreatePage from "../Pages/Create/page";
import ErrorPage from "../Pages/Error/page";

function RedirectorBase() {
  // document.title = "Brett Severin | Redirector";

  const [links, setLinks] = useState([
    {
      link: "https://www.linkedin.com/in/brettseverin/",
      shortcut: "linkedin",
    },
    {
      link: "https://www.instagram.com/brettseverin/",
      shortcut: "instagram",
    },
  ]);

  return (
    <Fragment>
      <Switch>
        {links.map((link) => (
          <Route
            exact
            path={"/r/" + link.shortcut}
            component={() => {
              window.location.href = link.link;
            }}
          />
        ))}
        {/* <Route exact path={"/r/create"} component={CreatePage} />
        <Route path={"/r"} component={ErrorPage} /> */}
      </Switch>
    </Fragment>
  );
}

export default RedirectorBase;
