import React, { Fragment, useState, useEffect } from "react";

import "./Input.scss";

function Input(props) {
  const label = props.label ? props.label : "";
  const name = props.name ? props.name : "";
  const value = props.value ? props.value : "";
  const setValue = props.setValue ? props.setValue : null;
  const type = props.type ? props.type : "text";
  const disabled = props.disabled ? props.disabled : false;
  const notInput = props.notInput;
  const required = props.required;

  return (
    <Fragment>
      <div className={"myinput"}>
        {!notInput ? (
          <input
            name={name}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={" "}
            type={type}
            disabled={disabled}
            min={props.min}
            max={props.max}
            step={props.step}
            required={required}
          />
        ) : (
          <textarea
            name={name}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={" "}
            disabled={disabled}
            required={required}
          ></textarea>
        )}
        <label for={label}>{label}</label>
      </div>
    </Fragment>
  );
}

export default Input;
