import React, { Fragment, useState, useEffect } from "react";
import { Link, Route } from "react-router-dom";
import Model from "./Model/Model";

import "./Show.scss";

function Show(props) {
  let data = {
    title: "Spider-Man: No Way Home",
    image:
      "https://m.media-amazon.com/images/M/MV5BZWMyYzFjYTYtNTRjYi00OGExLWE2YzgtOGRmYjAxZTU3NzBiXkEyXkFqcGdeQXVyMzQ0MzA0NTM@._V1_.jpg",
    id: "1",
  };

  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <Link className={"show"} to={"/showfeur"}>
        <button onClick={() => setOpen(!open)}>100</button>
        <img src={data.image} alt={data.title + " poster"} />
        {data.title}
      </Link>
      <Model open={open} setOpen={setOpen} />
    </Fragment>
  );
}

export default Show;
