import React, { createContext, useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

export const AdminContext = createContext();

function AdminContextProvider(props) {
  const [users, setUsers] = useState([
    {
      id: 1,
      first_name: "Shelby",
      last_name: "Perelli",
      username: "shelby",
      password: " ",
    },
  ]);

  const [user, setUser] = useState(null);

  function verifyUser(username, password) {
    const thisUser = users.find((user) => user.username === username);

    if (thisUser && thisUser.password === password) {
      setUser(thisUser);
      return true;
    }
    return false;
  }

  function isLoggedIn() {
    return user !== null || false;
  }

  function getUserName(lastName = false) {
    if (!user) return "";
    var name = user.first_name;

    if (lastName) {
      name += " " + user.last_name;
    }

    return name;
  }

  return (
    <AdminContext.Provider
      value={{
        verifyUser: verifyUser,
        isLoggedIn: isLoggedIn,
        getUserName: getUserName,
      }}
    >
      {!isLoggedIn() && <Redirect to={"/admin/login"} />}
      {props.children}
    </AdminContext.Provider>
  );
}

export default AdminContextProvider;

export const useAdminContext = () => useContext(AdminContext);
