import React from "react";

import Alphabet from "./Alphabet/Alphabet";
import Match from "./Match/Match";
import Minesweeper from "./Minesweeper/Minesweeper";
import Snake from "./Snake/Snake";
import Wordle from "./Wordle/Wordle";

const games = [
  {
    id: "minesweeper",
    title: "Minesweeper",
    element: <Minesweeper />,
  },
  {
    id: "alphabet",
    title: "Alphabet",
    element: <Alphabet />,
  },
  {
    id: "snake",
    title: "Snake",
    element: <Snake />,
  },
  {
    id: "match",
    title: "Match",
    element: <Match />,
  },
  {
    id: "wordle",
    title: "Wordle",
    element: <Wordle />,
  },
];

function getGame(id) {
  const game = games.find((game) => game.id === id);
  return game
    ? game
    : {
        id: null,
        title: "Game not found.",
        element: <p>{"Game not found"}</p>,
      };
}

export function getGames() {
  return games;
}

export default getGame;
