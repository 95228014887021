import React, { Fragment, useState, useEffect } from "react";
import { useMCATContext } from "../../../../../../../Contexts/context";

import styles from "./Task.module.scss";

function Task(props) {
  const mcatContext = useMCATContext();
  const data = props.data ? props.data : null;

  const [title, setTitle] = useState(data.title);

  function toggleDone() {
    var newTask = { ...data };
    newTask.isDone = !newTask.isDone;
    mcatContext.editTask(data.id, newTask);
  }

  return (
    <Fragment>
      <li className={styles.task}>
        <input value={title} onChange={(e) => setTitle(e.target.value)} />
        <button
          onClick={() => toggleDone()}
          className={`${data.isDone ? styles.isDone : ""} slide`}
        >
          {title}
        </button>
        <button
          onClick={() =>
            window.confirm("Are you sure you want to delete this task?") ? mcatContext.deleteTask(data.id) : ""
          }
          className={"material-icons"}
        >
          close
        </button>
      </li>
    </Fragment>
  );
}

export default Task;
