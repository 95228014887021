import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";

import styles from "./Contact.module.scss";

import Input from "../../../Elements/Input/Input";

function Contact() {
  const API_PATH = "http://localhost:5000/contact";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState(``);

  const [mailSent, setMailSent] = useState(false);

  function handleFormSubmit(e) {
    e.preventDefault();

    window.location.href =
      "mailto:contact@brettseverin.com?subject=" +
      subject +
      "&body=" +
      message.replaceAll(/\n/g, "%0D%0A");
  }

  return (
    <Fragment>
      <section id={"contact"} className={styles.contact}>
        <header>
          <h2>Get in Touch</h2>
          <h1>Contact Me</h1>
        </header>

        <form onSubmit={handleFormSubmit}>
          <Input
            label={"Your name"}
            name={"name"}
            value={name}
            setValue={setName}
            type={"name"}
            required
          />
          <Input
            label={"Your email"}
            name={"email"}
            type={"email"}
            value={email}
            setValue={setEmail}
            required
          />
          <Input
            label={"Subject"}
            name={"subject"}
            type={"text"}
            value={subject}
            setValue={setSubject}
            required
          />
          <Input
            label={"Message"}
            name={"body"}
            value={message}
            setValue={setMessage}
            notInput
            required
          />
          <input type={"submit"} value={"Send message"} />
          {mailSent ? "Mail sent successfully" : ""}
        </form>
      </section>
    </Fragment>
  );
}

export default Contact;
