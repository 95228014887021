const TestTopics = [
  {
    id: 1,
    title: "Elements",
    subject: "Chemistry",
    skill: 0,
  },
  {
    id: 2,
    title: "Gravity",
    subject: "Physics",
    skill: 0,
  },
  {
    id: 3,
    title: "Compounds",
    subject: "Chemistry",
    skill: 0,
  },
  {
    id: 4,
    title: "Cells",
    subject: "Biology",
    skill: 0,
  },
  {
    id: 5,
    title: "Bones",
    subject: "Anatomy",
    skill: 0,
  },
  {
    id: 6,
    title: "Skin",
    subject: "Anatomy",
    skill: 0,
  },
  {
    id: 7,
    title: "Reading",
    subject: "CARS",
    skill: 0,
  },
  {
    id: 8,
    title: "Viruses",
    subject: "Microbiology",
    skill: 0,
  },
  {
    id: 9,
    title: "Carbon",
    subject: "Organic Chemistry",
    skill: 0,
  },
  {
    id: 10,
    title: "Molecules",
    subject: "Biochemistry",
    skill: 0,
  },
];

export default TestTopics;
