import React, { Fragment, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import PortfolioPage from "../page";

function PortfolioBase(props) {
  document.title = "Brett Severin";

  return (
    <Fragment>
      <Switch>
        <Route exact path={"/"} component={PortfolioPage} />
      </Switch>
    </Fragment>
  );
}

export default PortfolioBase;
