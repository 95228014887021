import React, { Fragment, useState, useEffect } from "react";
import { Link, Route } from "react-router-dom";
import Movie from "./Elements/Movie/Movie";
import Navigation from "./Elements/Navigation/Navigation";
import { useShowfeurContext } from "./Context";

import "./page.scss";
import Show from "./Elements/Show/Show";

function Showfeur() {
  document.title = "Brett Severin | Showfeur";

  const context = useShowfeurContext();

  return (
    <Fragment>
      <div id={"showfeur-page"}>
        <Navigation />
        <h1>In progress</h1>
        <div>
          <Movie />
          <Show />
        </div>
        <h1>Up next</h1>
        <div>
          <Movie />
          <Show />
        </div>
        <h1>Watched</h1>
        <div>
          <Movie />
          <Show />
        </div>
      </div>
    </Fragment>
  );
}

export default Showfeur;
