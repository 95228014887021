import React, { Fragment, useState, useEffect } from "react";

import styles from "./Projects.module.scss";

function Projects() {
  return (
    <Fragment>
      <section id={styles.projects}>
        <header>
          <h2>Things I Have Made</h2>
          <h1>My Projects</h1>
        </header>
        <div className={styles.unreleased}>Coming soon</div>
      </section>
    </Fragment>
  );
}

export default Projects;
