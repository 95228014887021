import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Link, Route, Redirect, Switch } from "react-router-dom";

import "./Dropdown.scss";

function Dropdown(props) {
  const [open, setOpen] = useState(false);

  const seleted = props.selected ? props.selected : "";

  return (
    <Fragment>
      <span className={"dropdown"}>
        <button onClick={() => setOpen(!open)}>{seleted}</button>
        <ul data-open={open}>
          {props.children.map((child) => (
            <li onClick={() => setOpen(!open)}>{child}</li>
          ))}
        </ul>
      </span>
    </Fragment>
  );
}

export default Dropdown;
