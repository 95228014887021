import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import styles from "./About.module.scss";

function About() {
  return (
    <Fragment>
      <section id={styles.about}>
        <header>
          <h2>Who Am I</h2>
          <h1>About Me</h1>
        </header>
        <div>
          <span>
            <img
              src={
                "https://media-exp1.licdn.com/dms/image/C5603AQHqt5YEk0OJGw/profile-displayphoto-shrink_400_400/0/1557133537570?e=1654128000&v=beta&t=6dR-4wA1vDIblPt_CMD1i3HuCFWLoaJQ7N6dppvEsWQ"
              }
            />
          </span>
          <div>
            <ul>
              <li>
                <i>work</i>
                <h1>Experience</h1>
                <p>2+ years</p>
              </li>
              <li>
                <i>school</i>
                <h1>Education</h1>
                <p>Pepperdine University</p>
              </li>
              <li>
                <i>class</i>
                <h1>Bachelor of Science</h1>
                <p>
                  Computer Science<br></br>Business Administration
                </p>
              </li>
            </ul>
            <p>
              In 2022, I graduated Pepperdine University with a Bachelor of
              Science in Computer Science and Business where I studied in
              Malibu, London, and Washington, DC. I enjoy programming,
              investing, and watching television. When I was 13 years old, I
              learned Swift and created a mobile game for iOS. I have experience
              designing UI, programming websites and webservers, specifying
              APIs, and manipulating data in a SQL database.
            </p>
            <Link to={"/#contact"}>Contact me</Link>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default About;
