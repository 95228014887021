import React, { Fragment, useState, useEffect } from "react";
import { BrowserRouter, Link, Route } from "react-router-dom";
import { useMCATContext } from "../../../../Contexts/context";

import styles from "./Calendar.module.scss";

function Calendar(props) {
  const mcatContext = useMCATContext();

  const format = props.format ? props.format : "month";
  const selectedDate = props.date ? props.date : null;
  const [date, setDate] = useState(props.date ? props.date : new Date());

  function getMonthLength() {
    var thisDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return thisDate.getDate();
  }

  function getWeekday(day) {
    const newDate = new Date(date.getFullYear(), date.getMonth(), day);
    return newDate.getDay() + 1;
  }

  function changeMonth(change) {
    var month = date.getMonth() + change;

    var newDate;
    if (month < 0) {
      newDate = new Date(date.getFullYear(), month, 1);
    } else if (month > 11) {
      newDate = new Date(date.getFullYear(), month, 1);
    } else {
      newDate = new Date(date.getFullYear(), month, 1);
    }

    setDate(newDate);
  }

  function isSelected(thisDate) {
    return (
      selectedDate &&
      selectedDate.getDate() === thisDate &&
      selectedDate.getMonth() === date.getMonth() &&
      selectedDate.getFullYear() === date.getFullYear()
    );
  }

  function isToday(thisDate) {
    const today = new Date();
    return (
      today.getDate() === thisDate &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
    );
  }

  const days = Array(getMonthLength())
    .fill(null)
    .map((_, i) => (
      <li>
        <Link
          to={
            "/mcat/log/" +
            [format, date.getFullYear(), date.getMonth() + 1, i + 1].join("/")
          }
          className={`${isSelected(i + 1) ? styles.selected : ""} ${
            isToday(i + 1) ? styles.today : ""
          }`}
          allowMultiple={true}
        >
          {i + 1}
        </Link>
      </li>
    ));

  return (
    <Fragment>
      <div className={styles.calendar}>
        <header>
          <h1>
            {date.getFullMonth()} {date.getFullYear()}
          </h1>
          <div>
            <button
              className={"material-icons slide"}
              onClick={() => changeMonth(-1)}
            >
              keyboard_arrow_left
            </button>
            <button
              className={"material-icons slide"}
              onClick={() => changeMonth(1)}
            >
              keyboard_arrow_right
            </button>
          </div>
        </header>
        <ul style={{ "--start": getWeekday(1) }}>
          {["S", "M", "T", "W", "T", "F", "S"].map((day) => (
            <li>{day}</li>
          ))}
          {days}
        </ul>
      </div>
    </Fragment>
  );
}

export default Calendar;
