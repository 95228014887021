import React, { createContext, useState, useContext } from "react";

export const ShowfeurContext = createContext();

function ShowfeurContextProvider(props) {
  const [watchList, setWatchList] = useState([]);
  const [inProgressList, setInProgressList] = useState([]);
  const [upNextList, setUpNextList] = useState([]);

  // Watch list functions
  function isWatched(id) {
    return watchList.includes(id);
  }

  function toggleWatched(id) {
    var newList = [...watchList];
    if (isWatched(id)) {
      newList = newList.filter((it) => it !== id);
    } else {
      newList.push(id);
    }
    setWatchList(newList);
  }

  // In progress list functions
  function isInProgress(id) {
    return inProgressList.includes(id);
  }

  function toggleInProgress(id) {
    var newList = [...inProgressList];
    if (isInProgress(id)) {
      newList = newList.filter((it) => it !== id);
    } else {
      newList.push(id);
    }
    setInProgressList(newList);
  }

  // Up next functions
  function isUpNext(id) {
    return upNextList.includes(id);
  }

  function toggleUpNext(id) {
    var newList = [...upNextList];
    if (isUpNext(id)) {
      newList = newList.filter((it) => it !== id);
    } else {
      newList.push(id);
    }
    setUpNextList(newList);
  }

  return (
    <ShowfeurContext.Provider
      value={{
        isWatched: isWatched,
      }}
    >
      {props.children}
    </ShowfeurContext.Provider>
  );
}

export default ShowfeurContextProvider;

export const useShowfeurContext = () => useContext(ShowfeurContext);
