import React, { Fragment } from "react";

import styles from "./Footer.module.scss";

function Footer() {
  const year = new Date().getFullYear();

  return (
    <Fragment>
      <footer id={styles.footer}>
        Created by Brett Severin © {year}. All rights reserved.
      </footer>
    </Fragment>
  );
}

export default Footer;
