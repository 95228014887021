import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import Input from "../../../../Elements/Input/Input";

import logo from "../../logo.png";

import { useMCATContext } from "../../Contexts/context";

import "./page.scss";

function AuthPage(props) {
  const mcatContext = useMCATContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [verified, setVerified] = useState(null);

  return (
    <Fragment>
      {mcatContext.isLoggedIn() ? <Redirect to={"/mcat"} /> : ""}
      <div id={"AuthPage"}>
        <img src={logo} alt={"MCAT Logo"} />
        <h1>MCAT</h1>
        <Input label={"Username"} value={username} setValue={setUsername} />
        <Input
          label={"Password"}
          value={password}
          setValue={setPassword}
          type={"password"}
        />
        <button
          className={"slide"}
          onClick={() =>
            setVerified(mcatContext.verifyUser(username, password))
          }
        >
          Log in
        </button>
        {verified === null ? (
          ""
        ) : !verified ? (
          <p>Incorrect username or password</p>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
}

export default AuthPage;
