const TestTasks = [
  {
    id: 1,
    title: "Anatomy",
    isDone: true,
    rank: 1,
    date: "2022-03-01",
    color: "#bae1ff",
  },
  {
    id: 2,
    title: "Physiology",
    isDone: true,
    rank: 2,
    date: "2022-03-02",
    color: "#baffc9",
  },
  {
    id: 3,
    title: "Urology",
    isDone: true,
    rank: 3,
    date: "2022-03-05",
    color: "#ffdfba",
  },
  {
    id: 4,
    title: "Cells",
    isDone: false,
    rank: 4,
    date: "2022-03-04",
    color: "#ffffba",
  },
  {
    id: 5,
    title: "Atoms",
    isDone: false,
    rank: 5,
    date: "2022-03-03",
    color: "#ffdfba",
  },
  {
    id: 6,
    title: "CRA",
    isDone: false,
    rank: 6,
    date: "2022-03-03",
    color: "#ffb3ba",
  },
  {
    id: 7,
    title: "Anatomy",
    isDone: true,
    rank: 1,
    date: "2022-03-01",
    color: "#bae1ff",
  },
  {
    id: 8,
    title: "Anatomy",
    isDone: true,
    rank: 1,
    date: "2022-03-01",
    color: "#bae1ff",
  },
  {
    id: 9,
    title: "Anatomy",
    isDone: true,
    rank: 1,
    date: "2022-03-01",
    color: "#bae1ff",
  },
  {
    id: 10,
    title: "Anatomy",
    isDone: true,
    rank: 1,
    date: "2022-03-01",
    color: "#bae1ff",
  },
  {
    id: 11,
    title: "Anatomy",
    isDone: true,
    rank: 1,
    date: "2022-03-01",
    color: "#bae1ff",
  },
  {
    id: 12,
    title: "Anatomy",
    isDone: true,
    rank: 1,
    date: "2022-03-01",
    color: "#bae1ff",
  },
];

export default TestTasks;
